import { ChevronRightIcon } from "@heroicons/react/24/solid";
import type { ReactNode } from "react";
import { useMatches } from "react-router";
import { NavLink } from "react-router-dom";

export interface BreadCrumbsHandle {
  href?: (match: Match) => string;
  crumb: (match: Match) => string | ReactNode;
}

export interface Match {
  id: string;
  pathname: string;
  params: Record<string, string>;
  data: unknown;
  handle: BreadCrumbsHandle;
}

const BreadCrumbs = () => {
  const matches = useMatches() as Match[];
  const crumbs = matches.filter((match) => Boolean(match.handle?.crumb));

  return (
    <ol className="flex items-center space-x-4">
      {crumbs.map((match, index) => {
        const handle: BreadCrumbsHandle = match.handle;

        let pathname = match.pathname;
        if (handle.href) {
          pathname = handle.href(match);
        }
        return (
          // biome-ignore lint/suspicious/noArrayIndexKey: No other useful key
          <li key={index}>
            <div className="flex">
              {index > 0 && (
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400 mr-4"
                  aria-hidden="true"
                />
              )}
              <NavLink
                to={pathname}
                className="text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {handle.crumb(match)}
              </NavLink>
            </div>
          </li>
        );
      })}
    </ol>
  );
};

export { BreadCrumbs };
