import { AnonymousLayout } from "@/layouts/AnonymousLayout";
import { AppLayout } from "@/layouts/AppLayout";
import { AuthenticatedLayout } from "@/layouts/AuthenticatedLayout";
import { NoMatch } from "@/layouts/NoMatch";
import { RouteErrorLayout } from "@/layouts/RouteErrorLayout";
import { ProviderCallback } from "@/pages/account/ProviderCallback";
import Login from "@/pages/account/login";
import * as countries from "i18n-iso-countries";
import { Navigate, Route, createRoutesFromElements } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import type { Match } from "./components/ui/BreadCrumbs";
import { VariantList } from "./pages/variants";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<AuthenticatedLayout />}>
        <Route
          path="/"
          element={<AppLayout />}
          errorElement={<RouteErrorLayout />}
        >
          <Route index element={<Navigate to="/submissions" replace />} />
          <Route
            path="variants"
            handle={{ crumb: () => "Variants" }}
            element={<VariantList />}
          />

          <Route path="submissions" handle={{ crumb: () => "submissions" }}>
            <Route index lazy={() => import("./pages/SubmissionList")} />
            <Route
              path=":isoCode"
              handle={{
                crumb: (match: Match) => {
                  return countries.getName(
                    match.params.isoCode as string,
                    "en",
                  );
                },
                href: (match: Match) => {
                  return `/countries/${match.params.isoCode}`;
                },
              }}
            >
              <Route
                path=":submission"
                lazy={() => import("./layouts/SubmissionDetailLayout")}
                handle={{
                  crumb: (match: Match) => {
                    return match.params.submission;
                  },
                }}
              >
                <Route
                  index
                  lazy={async () => {
                    const { SubmissionMetadata } = await import(
                      "./pages/submission"
                    );
                    return { Component: SubmissionMetadata };
                  }}
                />
                <Route
                  path="metadata"
                  lazy={async () => {
                    const { SubmissionMetadata } = await import(
                      "./pages/submission"
                    );
                    return { Component: SubmissionMetadata };
                  }}
                />
                <Route
                  path="coverage"
                  lazy={async () => {
                    const { SubmissionCoverage } = await import(
                      "./pages/submission"
                    );
                    return { Component: SubmissionCoverage };
                  }}
                />
                <Route
                  path="pathways"
                  lazy={async () => {
                    const { SubmissionPathways } = await import(
                      "./pages/submission"
                    );
                    return { Component: SubmissionPathways };
                  }}
                />
                <Route
                  path="input-data"
                  lazy={async () => {
                    const { SubmissionInputData } = await import(
                      "./pages/submission"
                    );
                    return { Component: SubmissionInputData };
                  }}
                />
                <Route
                  path="targets"
                  lazy={async () => {
                    const { SubmissionTargets } = await import(
                      "./pages/submission"
                    );
                    return { Component: SubmissionTargets };
                  }}
                />
                <Route
                  path="data"
                  lazy={async () => {
                    const { SubmissionData } = await import(
                      "./pages/submission"
                    );
                    return { Component: SubmissionData };
                  }}
                />
                <Route
                  path="history"
                  lazy={async () => {
                    const { SubmissionHistory } = await import(
                      "./pages/submission"
                    );
                    return { Component: SubmissionHistory };
                  }}
                />
              </Route>
            </Route>
          </Route>

          <Route path="countries" handle={{ crumb: () => "submissions" }}>
            <Route index lazy={() => import("./pages/CountryList")} />
            <Route
              path=":isoCode"
              handle={{
                crumb: (match: Match) => {
                  return countries.getName(
                    match.params.isoCode as string,
                    "en",
                  );
                },
              }}
              lazy={() => import("./layouts/CountryDetailLayout")}
            >
              <Route
                index
                lazy={async () => {
                  const { CountryOverview } = await import("./pages/countries");
                  return { Component: CountryOverview };
                }}
              />
              <Route
                path="overview"
                lazy={async () => {
                  const { CountryOverview } = await import("./pages/countries");
                  return { Component: CountryOverview };
                }}
              />
              <Route
                path="submissions"
                lazy={async () => {
                  const { CountrySubmissionList } = await import(
                    "./pages/countries"
                  );
                  return { Component: CountrySubmissionList };
                }}
              />
              <Route
                path="data"
                lazy={async () => {
                  const { CountryData } = await import("./pages/countries");
                  return { Component: CountryData };
                }}
              />
            </Route>
          </Route>

          <Route path="*" element={<NoMatch />} />
        </Route>
      </Route>
      <Route path="/account">
        <Route path="provider/callback" element={<ProviderCallback />} />
        <Route
          path="login"
          element={
            <AnonymousLayout>
              <Login />
            </AnonymousLayout>
          }
        />
      </Route>
    </>,
  ),
);

export { router };
