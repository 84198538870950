import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
// @ts-expect-error no declaration file for module
import apolloLogger from "apollo-link-logger";
import { toast } from "sonner";

import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";

const GRAPHQL_URI = `${import.meta.env.REACT_APP_NDC_TOOL_API}/graphql`;

loadErrorMessages();
loadDevMessages();

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    for (const { message, locations, path } of graphQLErrors) {
      const errString = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
      console.log(errString);
      toast(message);
    }
  if (networkError) {
    const errString = `[Network error]: ${networkError}`;
    console.log(errString);
    toast(errString);
  }
});

const httpLink = new HttpLink({
  uri: GRAPHQL_URI,
  credentials: "include",
});

const client = new ApolloClient({
  link: from([apolloLogger, errorLink, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: import.meta.env.DEV,
});

export default client;
