import { URLs, useAuthStatus } from "@/auth";
import type { PropsWithChildren } from "react";
import { Navigate } from "react-router";

export function AnonymousLayout({ children }: PropsWithChildren) {
  const [, status] = useAuthStatus();
  // @ts-ignore
  if (!status?.isAuthenticated) {
    return children;
  }
  return <Navigate to={URLs.LOGIN_REDIRECT_URL} />;
}
