import { PageHeader } from "@/components/ui/PageHeader";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
const VariantList = () => {
  return (
    <div>
      <PageHeader title="Variants" />

      <div className="p-8">
        <Card>
          <CardHeader>
            <CardTitle>Available Variant</CardTitle>
          </CardHeader>
          <CardContent>
            <div>TODO</div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export { VariantList };
