import type { AuthenticationResponse } from "@/auth/AuthContext";
import { Flows } from "@/lib/allauth/allauth";
import type { PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthChangeEvent, useAuthChange } from "./hooks";

export const URLs = Object.freeze({
  LOGIN_URL: "/account/login",
  LOGIN_REDIRECT_URL: "/submissions",
  LOGOUT_REDIRECT_URL: "/",
});

const flow2path = {
  [Flows.LOGIN]: "/account/login",
  // Still to implement the following flows:
  [Flows.LOGIN_BY_CODE]: "/account/login/code/confirm",
  [Flows.MFA_AUTHENTICATE]: "/account/2fa/authenticate",
  [Flows.MFA_REAUTHENTICATE]: "/account/2fa/reauthenticate",
  [Flows.REAUTHENTICATE]: "/account/reauthenticate",
  // Might not need to support the following flows:
  // Needed when the social provider doesn't provide enough information
  [Flows.PROVIDER_SIGNUP]: "/account/provider/signup",
  // Not planning on supporting adding multiple email addresses
  [Flows.VERIFY_EMAIL]: "/account/verify-email",
  // Not planning on supporting self-service signups
  [Flows.SIGNUP]: "/account/signup",
};

export function pathForFlow(flowId: string) {
  // @ts-ignore
  const path = flow2path[flowId];
  if (!path) {
    throw new Error(`Unknown path for flow: ${flowId}`);
  }
  return path;
}

export function pathForPendingFlow(auth: AuthenticationResponse) {
  const flow = auth.data.flows.find((flow) => flow.is_pending);
  if (flow) {
    return pathForFlow(flow.id);
  }
  return null;
}

function navigateToPendingFlow(auth: AuthenticationResponse) {
  const path = pathForPendingFlow(auth);
  if (path) {
    return <Navigate to={path} />;
  }
  return null;
}
export function AuthChangeRedirector({ children }: PropsWithChildren) {
  const [auth, event] = useAuthChange();
  const location = useLocation();

  switch (event) {
    case AuthChangeEvent.LOGGED_OUT:
      return <Navigate to={URLs.LOGOUT_REDIRECT_URL} />;
    case AuthChangeEvent.LOGGED_IN:
      return <Navigate to={URLs.LOGIN_REDIRECT_URL} />;
    case AuthChangeEvent.REAUTHENTICATED: {
      const next = new URLSearchParams(location.search).get("next") || "/";
      return <Navigate to={next} />;
    }
    case AuthChangeEvent.REAUTHENTICATION_REQUIRED: {
      const next = `next=${encodeURIComponent(
        location.pathname + location.search,
      )}`;
      const path = pathForFlow(
        // @ts-ignore
        (auth as AuthenticationResponse).data.flows[0].id,
      );
      return <Navigate to={`${path}?${next}`} state={{ reauth: auth }} />;
    }
    case AuthChangeEvent.FLOW_UPDATED: {
      const pendingFlow = navigateToPendingFlow(auth as AuthenticationResponse);
      if (!pendingFlow) {
        throw new Error();
      }
      return pendingFlow;
    }
    default:
      break;
  }
  // ...stay where we are
  return children;
}
