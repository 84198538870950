import { gql } from "@/__generated__";
import { AuthContextProvider } from "@/auth";
import { VariantProvider } from "@/hooks/useVariant";
import { ErrorLayout } from "@/layouts/ErrorLayout";
import { LoadingAppLayout } from "@/layouts/LoadingAppLayout";
import { ApolloProvider, useQuery } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { registerLocale } from "i18n-iso-countries";
import enCountryData from "i18n-iso-countries/langs/en.json";
import * as React from "react";
import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router";
import client from "./apolloClient";
import { router } from "./routes";
import "./styles.css";

if (process.env.NODE_ENV !== "production") {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

registerLocale(enCountryData);

const Main_Variants = gql(`
  query Main_Variants {
    variants {
      ...UseVariant_variants
    }
  }
`);

const App = () => {
  const { data, error } = useQuery(Main_Variants);
  if (error) {
    return <ErrorLayout message={"The API may not be available"} />;
  }

  if (data)
    return (
      <AuthContextProvider>
        <VariantProvider variantFragments={data.variants}>
          <RouterProvider router={router} />
        </VariantProvider>
      </AuthContextProvider>
    );
};

createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Suspense fallback={<LoadingAppLayout />}>
        <App />
      </Suspense>
    </ApolloProvider>
  </React.StrictMode>,
);
