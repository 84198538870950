import {
  type DocumentType,
  type FragmentType,
  gql,
  useFragment,
} from "@/__generated__";
import { type PropsWithChildren, createContext, useContext } from "react";

import { useLocalStorage } from "usehooks-ts";

export interface Variant {
  name: string;
}

export const UseVariant_variants = gql(/* GraphQl */ `
  fragment UseVariant_variants on Variant {
      name
      isActive
  }
`);

interface IVariantContext {
  selected: Variant;
  variants: readonly Variant[];
  setSelected: (variant: Variant) => void;
}

interface VariantProviderProps {
  variantFragments: Array<FragmentType<typeof UseVariant_variants>>;
}

const VariantContext = createContext<IVariantContext | undefined>(undefined);

export const VariantProvider = ({
  variantFragments,
  children,
}: PropsWithChildren<VariantProviderProps>) => {
  const variants: ReadonlyArray<DocumentType<typeof UseVariant_variants>> =
    useFragment(UseVariant_variants, variantFragments);

  const [selected, setSelected] = useLocalStorage<Variant>(
    "variant",
    variants[0] as Variant,
  );

  return (
    <VariantContext.Provider value={{ selected, setSelected, variants }}>
      {children}
    </VariantContext.Provider>
  );
};

export const useVariant = () => {
  const context = useContext(VariantContext);

  if (!context) {
    throw new Error("useVariant must be used inside the VariantProvider");
  }

  return context;
};
