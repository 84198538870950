import { URLs, pathForPendingFlow, useAuthStatus } from "@/auth";
import type { AuthenticationResponse } from "@/auth/AuthContext";
import { Link, Navigate, useLocation } from "react-router-dom";

export function ProviderCallback() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const error = params.get("error");
  const [auth, status] = useAuthStatus();

  let url: string = URLs.LOGIN_URL;

  if (auth?.status === 200 && status?.isAuthenticated) {
    url = URLs.LOGIN_REDIRECT_URL;
  } else {
    url = pathForPendingFlow(auth as AuthenticationResponse) || url;
  }
  if (!error) {
    return <Navigate to={url} />;
  }
  return (
    <>
      <h1>Third-Party Login Failure</h1>
      <p>Something went wrong.</p>
      <Link to={url}>Continue</Link>
    </>
  );
}
