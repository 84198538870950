import { Button } from "@/components/ui/button";
import type { ReactElement } from "react";
import { BreadCrumbs } from "./BreadCrumbs";

export interface Action {
  label: string;
  onClick?: () => void;
  render?: () => ReactElement;
}

interface PageHeaderProps {
  title: string;
  actions?: Action[];
}

const PageHeader = ({ title, actions }: PageHeaderProps) => {
  return (
    <div className="mx-8">
      <div>
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <BreadCrumbs />
        </nav>
      </div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 dark:text-gray-100 sm:truncate sm:text-3xl sm:tracking-tight">
            {title}
          </h2>
        </div>

        <div className="flex gap-4">
          {actions?.map((action) => {
            if (action.render) {
              return action.render();
            }

            return (
              <Button
                variant="default"
                key={action.label}
                onClick={action.onClick}
              >
                {action.label}
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { PageHeader };
