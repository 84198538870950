import { SkeletonSidebarDesktop } from "@/components/ui/Sidebar";

// Skeleton layout of the application
export function LoadingAppLayout() {
  return (
    <div>
      {/* Static sidebar for desktop */}
      <SkeletonSidebarDesktop />
      <div className="xl:pl-72 xl:pt-6">
        <main className="w-full h-screen flex items-center justify-center px-4 theme-zinc" />
      </div>
    </div>
  );
}
