import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

interface LoadingLayoutProps {
  message?: string;
}

export function ErrorLayout({ message }: LoadingLayoutProps) {
  return (
    <div className="w-full h-screen flex items-center justify-center px-4 theme-zinc">
      <Card className="mx-auto max-w-sm text-center">
        <CardHeader>
          <CardTitle className="text-2xl">Something went wrong</CardTitle>
        </CardHeader>

        <CardContent>
          <p>{message || "An error occurred while processing your request"}</p>
          <Button
            className="mt-4"
            onClick={() => {
              // Not using navigate here in case it was a routing error
              window.location.href = "/";
            }}
          >
            Click here to reload the app
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}
