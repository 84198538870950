const NoMatch = () => {
  return (
    <div className="my-32">
      <div className="flex flex-col items-center">
        <div className="text-indigo-500 font-bold text-5xl">404</div>

        <div className="font-bold text-3xl mt-10">This page does not exist</div>

        <div className="text-gray-400 font-medium text-lg mt-8">
          The page you are looking for could not be found.
        </div>
      </div>
    </div>
  );
};

export { NoMatch };
