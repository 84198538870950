import { SidebarDesktop } from "@/components/ui/Sidebar";
import { Toaster } from "@/components/ui/sonner";
import { Outlet } from "react-router";

const AppLayout = () => {
  return (
    <div>
      {/* Static sidebar for desktop */}
      <SidebarDesktop />
      <div className="xl:pl-72 xl:pt-6">
        <main>
          <Outlet />
        </main>
        <Toaster />
      </div>
    </div>
  );
};

export { AppLayout };
