import { useContext, useEffect, useRef, useState } from "react";
import { type Auth, AuthContext, type Config } from "./AuthContext";

interface AuthInfo {
  isAuthenticated: boolean;
  requiresReauthentication: boolean;
  user: any;
  pendingFlow: any;
}
export function useAuth(): Auth {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used inside the AuthContextProvider");
  }
  return context.auth as Auth;
}

export function useConfig(): Config {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useConfig must be used inside the AuthContextProvider");
  }
  return context.config as Config;
}

export function useUser() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useUser must be used inside the AuthContextProvider");
  }
  const auth = context.auth as Auth;
  return authInfo(auth).user;
}

function authInfo(auth: Auth): AuthInfo {
  const isAuthenticated =
    auth.status === 200 || (auth.status === 401 && auth.meta.is_authenticated);
  const requiresReauthentication = isAuthenticated && auth.status === 401;
  const pendingFlow =
    (auth.status === 401 &&
      auth.data?.flows?.find((flow) => flow.is_pending)) ??
    false;
  return {
    isAuthenticated,
    requiresReauthentication,
    // @ts-ignore
    user: isAuthenticated ? auth.data?.user : null,
    pendingFlow,
  };
}

export const AuthChangeEvent = Object.freeze({
  LOGGED_OUT: "LOGGED_OUT",
  LOGGED_IN: "LOGGED_IN",
  REAUTHENTICATED: "REAUTHENTICATED",
  REAUTHENTICATION_REQUIRED: "REAUTHENTICATION_REQUIRED",
  FLOW_UPDATED: "FLOW_UPDATED",
});

function determineAuthChangeEvent(fromAuth: Auth, toAuth: Auth) {
  let fromInfo = authInfo(fromAuth);
  const toInfo = authInfo(toAuth);
  if (toAuth.status === 410) {
    return AuthChangeEvent.LOGGED_OUT;
  }
  // Corner case: user ID change. Treat as if we're transitioning from anonymous state.
  if (fromInfo.user && toInfo.user && fromInfo.user?.id !== toInfo.user?.id) {
    fromInfo = {
      isAuthenticated: false,
      requiresReauthentication: false,
      user: null,
      pendingFlow: false,
    };
  }
  if (!fromInfo.isAuthenticated && toInfo.isAuthenticated) {
    // You typically don't transition from logged out to reauthentication required.
    return AuthChangeEvent.LOGGED_IN;
  }
  if (fromInfo.isAuthenticated && !toInfo.isAuthenticated) {
    return AuthChangeEvent.LOGGED_OUT;
  }
  if (fromInfo.isAuthenticated && toInfo.isAuthenticated) {
    if (toInfo.requiresReauthentication) {
      return AuthChangeEvent.REAUTHENTICATION_REQUIRED;
    }
    if (fromInfo.requiresReauthentication) {
      return AuthChangeEvent.REAUTHENTICATED;
    }
    // @ts-ignore
    if (fromAuth.data?.methods.length < toAuth.data?.methods.length) {
      // If you do a page reload when on the reauthentication page, both fromAuth
      // and toAuth are authenticated, and it won't see the change when
      // reauthentication without this.
      return AuthChangeEvent.REAUTHENTICATED;
    }
  } else if (!fromInfo.isAuthenticated && !toInfo.isAuthenticated) {
    const fromFlow = fromInfo.pendingFlow;
    const toFlow = toInfo.pendingFlow;
    if (toFlow && fromFlow && toFlow?.id && fromFlow?.id !== toFlow.id) {
      return AuthChangeEvent.FLOW_UPDATED;
    }
  }
  // No change.
  return null;
}

interface EventRef {
  prevAuth: Auth;
  event: string | null;
  didChange: boolean;
}

export function useAuthChange(): [Auth, string | null] {
  const auth = useAuth();
  const ref = useRef<EventRef>({
    prevAuth: auth,
    event: null,
    didChange: false,
  });
  const [, setForcedUpdate] = useState(0);
  useEffect(() => {
    if (ref.current.prevAuth) {
      ref.current.didChange = true;
      const event = determineAuthChangeEvent(ref.current.prevAuth, auth);
      if (event) {
        ref.current.event = event;
        setForcedUpdate((gen) => gen + 1);
      }
    }
    ref.current.prevAuth = auth;
  }, [auth]);
  const didChange = ref.current.didChange;
  if (didChange) {
    ref.current.didChange = false;
  }
  const event = ref.current.event;
  if (event) {
    ref.current.event = null;
  }

  return [auth, event];
}

export function useAuthStatus(): [Auth, AuthInfo] {
  const auth = useAuth();
  return [auth, authInfo(auth)];
}
