import { clsx } from "clsx";
import { NavLink } from "react-router-dom";
import type { NavItem } from "./common";

export const NavLinkItem = (item: NavItem) => {
  return (
    <NavLink
      to={item.href}
      className={({ isActive }) =>
        clsx(
          isActive
            ? "bg-gray-800 text-white"
            : "text-gray-400 hover:text-white hover:bg-gray-800",
          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
        )
      }
    >
      <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
      {item.name}
    </NavLink>
  );
};
