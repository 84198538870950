import { ErrorLayout } from "@/layouts/ErrorLayout";
import { LoadingAppLayout } from "@/layouts/LoadingAppLayout";
import { getAuth, getConfig } from "@/lib/allauth/allauth";
import type { components } from "@/lib/allauth/schema";
import type * as schema from "@/lib/allauth/schema";
import {
  type PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";

export type Config = schema.components["schemas"]["ConfigurationResponse"];

export type AuthenticationResponse =
  components["schemas"]["AuthenticationResponse"];

export type Auth =
  | components["schemas"]["AuthenticatedResponse"]
  | AuthenticationResponse
  | components["schemas"]["SessionGoneResponse"];

export interface AuthContextValue {
  auth?: Auth | false;
  config?: Config;
}

export const AuthContext = createContext<AuthContextValue | undefined>(
  undefined,
);

export function AuthContextProvider(props: PropsWithChildren) {
  const [auth, setAuth] = useState<Auth | false | undefined>(undefined);
  const [config, setConfig] = useState<Config | undefined>(undefined);

  useEffect(() => {
    function onAuthChanged(e: CustomEvent) {
      setAuth((auth) => {
        if (typeof auth === "undefined") {
          console.log("Authentication status loaded");
        } else {
          console.log("Authentication status updated");
        }
        return e.detail;
      });
    }

    // @ts-ignore
    document.addEventListener("allauth.auth.change", onAuthChanged);
    getAuth()
      .then((data) => setAuth(data))
      .catch(() => {
        setAuth(false);
      });
    getConfig()
      .then((data) => setConfig(data))
      .catch((e) => {
        console.error(e);
      });
    return () => {
      // @ts-ignore
      document.removeEventListener("allauth.auth.change", onAuthChanged);
    };
  }, []);
  const loading = typeof auth === "undefined" || config?.status !== 200;
  return (
    <AuthContext.Provider value={{ auth, config }}>
      {loading ? (
        <LoadingAppLayout />
      ) : auth === false ? (
        <ErrorLayout message={"Failed to authenticate"} />
      ) : (
        props.children
      )}
    </AuthContext.Provider>
  );
}
