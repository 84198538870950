import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { clsx } from "clsx";
import { type NavItem, navigation, teams } from "./common";

export const NavLinkItem = (item: NavItem) => {
  return (
    <div
      className="text-gray-400 hover:text-white hover:bg-gray-800 group
      flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
    >
      <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
      {item.name}
    </div>
  );
};

export function SkeletonSidebarDesktop() {
  return (
    <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
      <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white dark:bg-black/10 px-6 ring-1 ring-white/5">
        <div className="flex h-16 shrink-0 items-center justify-between">
          <img
            className="h-10 w-auto"
            src="/CR_hoop.svg"
            alt="Climate Resource"
          />
          <div className="flex gap-2">
            <ChevronLeftIcon className="h-8 w-8 flex-shrink-0 text-gray-400 cursor-pointer" />
            <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-gray-400 cursor-pointer" />
          </div>
        </div>
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="-mx-2 space-y-1">
                {navigation.map((item) => (
                  <li key={item.name}>
                    <NavLinkItem {...item} />
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <div className="text-xs font-semibold leading-6 text-gray-400">
                Your teams
              </div>
              <ul className="-mx-2 mt-2 space-y-1">
                {teams.map((team) => (
                  <li key={team.name}>
                    <a
                      href={team.href}
                      className={clsx(
                        team.current
                          ? "bg-gray-800 text-white"
                          : "text-gray-400 hover:text-white hover:bg-gray-800",
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                      )}
                    >
                      <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                        {team.initial}
                      </span>
                      <span className="truncate">{team.name}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li className="-mx-6 mt-auto">
              <a
                href="/user/me"
                className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white bg-gray-800"
              >
                <span className="sr-only">Your profile</span>
                <span aria-hidden="true">
                  <Skeleton className="h-4 w-[100px]" />
                </span>

                <Button className="ml-auto" variant="ghost">
                  Logout
                </Button>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
