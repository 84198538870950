import { gql } from "@/__generated__";
import { Button } from "@/components/ui/button";
import { logout } from "@/lib/allauth";
import { useQuery } from "@apollo/client";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { clsx } from "clsx";
import { useNavigate } from "react-router";
import { Tooltip } from "react-tooltip";
import { NavLinkItem } from "./NavLinkItem";
import { navigation, teams } from "./common";

const GET_ME = gql(`
query me {
  me {
    email
  }
}
`);

const SidebarDesktop = () => {
  const navigate = useNavigate();

  const { data, loading } = useQuery(GET_ME);
  return (
    <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
      <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white dark:bg-black/10 px-6 ring-1 ring-white/5">
        <div className="flex h-16 shrink-0 items-center justify-between">
          <img
            className="h-10 w-auto"
            src="/CR_hoop.svg"
            alt="Climate Resource"
          />
          <div className="flex gap-2">
            <ChevronLeftIcon
              data-tooltip-id="navigate-tooltip"
              data-tooltip-content="Previous page"
              className="h-8 w-8 flex-shrink-0 text-gray-400 cursor-pointer"
              onClick={() => {
                navigate(-1);
              }}
            />
            <ChevronRightIcon
              data-tooltip-id="navigate-tooltip"
              data-tooltip-content="Next page"
              className="h-8 w-8 flex-shrink-0 text-gray-400 cursor-pointer"
              onClick={() => {
                navigate(1);
              }}
            />
            <Tooltip id="navigate-tooltip" />
          </div>
        </div>
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="-mx-2 space-y-1">
                {navigation.map((item) => (
                  <li key={item.name}>
                    <NavLinkItem {...item} />
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <div className="text-xs font-semibold leading-6 text-gray-400">
                Your teams
              </div>
              <ul className="-mx-2 mt-2 space-y-1">
                {teams.map((team) => (
                  <li key={team.name}>
                    <a
                      href={team.href}
                      className={clsx(
                        team.current
                          ? "bg-gray-800 text-white"
                          : "text-gray-400 hover:text-white hover:bg-gray-800",
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                      )}
                    >
                      <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                        {team.initial}
                      </span>
                      <span className="truncate">{team.name}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li className="-mx-6 mt-auto">
              <a
                href="/user/me"
                className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white bg-gray-800"
              >
                <span className="sr-only">Your profile</span>
                <span aria-hidden="true">
                  {!loading && data?.me ? data?.me.email : "Unknown User"}
                </span>

                <Button className="ml-auto" variant="ghost" onClick={logout}>
                  Logout
                </Button>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export { SidebarDesktop };
