import { URLs, useAuthStatus } from "@/auth";
import { Navigate, Outlet } from "react-router";
import { useLocation } from "react-router-dom";

export function AuthenticatedLayout() {
  const location = useLocation();
  const [, status] = useAuthStatus();
  const next = `next=${encodeURIComponent(
    location.pathname + location.search,
  )}`;
  // @ts-ignore
  if (status?.isAuthenticated) {
    return <Outlet />;
  }
  return <Navigate to={`${URLs.LOGIN_URL}?${next}`} />;
}
