import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormRootError,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { login } from "@/lib/allauth";

const formSchema = z.object({
  email: z.string().email({
    message: "A valid email is required.",
  }),
  password: z.string().min(4, {
    message: "Password must be at least 4 characters long.",
  }),
});

export function LoginForm() {
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    // Call the login function from allauth.ts
    const res = await login(values);

    if (res.status === 400) {
      // login failed, show the error message
      res.errors?.map((err) => {
        // @ts-ignore
        form.setError(err.param ?? "root", {
          type: "manual",
          message: err.message,
        });
      });
    } else if (res.status === 401) {
      // login failed, show the error message
      const verifyEmailState = res.data.flows.find(
        (flow) => flow.id === "verify_email",
      );
      if (verifyEmailState?.is_pending) {
        form.setError("root", {
          type: "manual",
          message: "Your email is not verified. Please check your inbox.",
        });
      } else {
        form.setError("root", {
          type: "manual",
          message: "An unknown error occurred.",
        });
      }
    } else if (res.status === 200) {
      // login successful, navigate to the home page
      navigate("/");
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="user@example.com" type="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormRootError />

        <Button type="submit" className="w-full">
          Login
        </Button>
      </form>
    </Form>
  );
}
