import { useConfig } from "@/auth";
import { Button } from "@/components/ui/button";
import { AuthProcess, redirectToProvider } from "@/lib/allauth/allauth";

interface ProviderListProps {
  callbackURL: string;
  process?: string;
}

export function ProviderList({
  callbackURL,
  process = AuthProcess.LOGIN,
}: ProviderListProps) {
  const config = useConfig();
  const providers = config.data?.socialaccount?.providers ?? [];
  if (!providers.length) {
    return null;
  }
  return (
    <>
      <ul>
        {providers.map((provider) => {
          return (
            <li key={provider.id}>
              <Button
                variant="outline"
                onClick={() =>
                  // @ts-ignore
                  redirectToProvider(provider.id, callbackURL, process)
                }
                className="w-full"
              >
                Login with {provider.name}
              </Button>
            </li>
          );
        })}
      </ul>
    </>
  );
}
