import {
  Cog6ToothIcon,
  FolderIcon,
  GlobeAsiaAustraliaIcon,
  ServerIcon,
} from "@heroicons/react/24/outline";

type Icon = React.FC<Parameters<typeof FolderIcon>[0]>;
export interface NavItem {
  name: string;
  href: string;
  icon: Icon;
}

// List of nav links on the sidebar
const navigation: NavItem[] = [
  { name: "Variants", href: "/variants", icon: FolderIcon },
  { name: "Submissions", href: "/submissions", icon: ServerIcon },
  { name: "Countries", href: "/countries", icon: GlobeAsiaAustraliaIcon },
  { name: "Regions", href: "/regions", icon: GlobeAsiaAustraliaIcon },
  { name: "Settings", href: "/settings", icon: Cog6ToothIcon },
];

const teams = [
  {
    id: 1,
    name: "Climate Resource",
    href: "#",
    initial: "CR",
    current: true,
  },
];

export { navigation, teams };
